<template>
  <nav
    class="mobile-nav"
  >
    <b-link
      v-for="menuOption in mobileNavigation"
      :key="menuOption.route"
      :to="menuOption.route"
      :class="['bloc-icon', route === menuOption.route.name ? 'selected' : '']"
    >
      <feather-icon
        :icon="menuOption.icon"
        size="21"
      />
    </b-link>
  </nav>
</template>

<script>
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import reactiveNavigation from '@/navigation/vertical/reactiveNavigation'

export default {
  components: {
    FeatherIcon,
  },
  setup() {
    const { mobileNavigation, route } = reactiveNavigation()
    return {
      mobileNavigation,
      route,
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/horizontal-menu.scss";
</style>

<style>
.nav{
    display: block !important;
}
.mobile-nav {
  background: var(--secondary-custom) !important;
  position: fixed;
  bottom: 0;
  height: 65px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.bloc-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}
.bloc-icon.selected{
  color: black;
  background-color: white;
  text-decoration: none;
  height: 26px;
  width: 26px;
  border-radius: 50%;
  padding: 3px;
}
@media screen and (min-width: 821px) {
  .mobile-nav {
  display: none;
  }
}
</style>
